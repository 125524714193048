<template>
  <base-section
    id="contact-info"
    space="56"
  >
    <v-responsive
      class="mx-auto"
      max-width="1000"
    >
      <base-business-info>
      </base-business-info>
    </v-responsive>

  </base-section>
</template>

<script>

  export default {
    name: 'SectionContactInfo',
  }
</script>
